import { Box } from "@mui/material";
import { LoginButton } from "@telegram-auth/react";
import axios from "axios";
import { observer } from "mobx-react-lite";
import authStore from "../stores/AuthStore/authStore";

const TgAuth = observer(() => {
  const store = authStore;

  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          mt: 10,
        }}
      >
        <LoginButton
          botUsername={"bs_web_bot"}
          onAuthCallback={(data) => {
            console.log(data);
            axios
              .post(
                `https://webapp.bsolution.uz/backend/auth`,
                { withCredentials: true },
                { params: data }
              )
              .then((res) => {
                if (res.data) {
                  store.setAuth(res.data);
                }
              });
          }}
          buttonSize="medium" // "large" | "medium" | "small"
          cornerRadius={5} // 0 - 20
          showAvatar={false} // true | false
          lang="ru"
        />
      </Box>
    </>
  );
});
export default TgAuth;
