import {
  Alert,
  Card,
  CardActions,
  CardHeader,
  CircularProgress,
  ClickAwayListener,
  IconButton,
  Popper,
  Tooltip,
  styled,
} from "@mui/material";
import {
  Computer as ComputerIcon,
  PointOfSale as PointOfSaleIcon,
  Router as RouterIcon,
  Scale as ScaleIcon,
  Store as StoreIcon,
  Wifi as WifiIcon,
  MonetizationOn as MonetizationIcon,
  SettingsEthernet as EthernetIcon,
  FileCopy,
} from "@mui/icons-material";
import React, { useEffect } from "react";
import axios from "axios";

const StyledDiv = styled("div")(({ theme }) => ({
  cursor: "pointer",
  padding: "8px",
  transition: "0.5s",
  "&:hover": {
    color: theme.palette.primary.main,
    transition: "0.5s",
  },
}));
const renderHostName = (host) => {
  const ip = host.split(".");
  const last = ip[ip.length - 1];
  switch (last) {
    case "254":
      return ["Mikrotik", <RouterIcon fontSize="large" key="mikrotik" />];
    case "10":
      return ["Компьютер", <ComputerIcon fontSize="large" key="computer" />];
    case "160":
      return ["Unify", <WifiIcon fontSize="large" key="unify" />];
    case "60":
      return ["Весы", <ScaleIcon fontSize="large" key="scale" />];
    case "101":
      return ["Касса 1", <PointOfSaleIcon fontSize="large" key="pos1" />];
    case "102":
      return ["Касса 2", <PointOfSaleIcon fontSize="large" key="pos2" />];
    case "103":
      return ["Касса 3", <PointOfSaleIcon fontSize="large" key="pos3" />];
    default:
      return [
        "Неизвестное устройство",
        <StoreIcon fontSize="large" key="unknown" />,
      ];
  }
};

const EquipmentCard = ({ h }) => {
  const [open, setOpen] = React.useState(false);
  const [loadFiscal, setLoadFiscal] = React.useState(false);
  const [fiscalData, setFiscalData] = React.useState({});
  const [openPing, setOpenPing] = React.useState(false);
  const [openCopy, setOpenCopy] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);

  function fetchCashFiscalData(host) {
    axios
      .get(`https://webapp.bsolution.uz/backend/getFiscalData/${host}`)
      .then((response) => {
        setFiscalData(response.data);
        setLoadFiscal(false);
        console.log(`Данные с кассы для ${host}:`, response.data);
      })
      .catch((error) => {
        setLoadFiscal(false);
        console.error(
          `Ошибка при получении данных с кассы для ${host}:`,
          error
        );
      });
  }
  const lastSegment = h.host.split(".").pop();
  const isCash =
    lastSegment === "101" || lastSegment === "102" || lastSegment === 103;

  useEffect(() => {
    return () => {};
  }, [h.host]);

  const handleClick = (event) => {
    event.preventDefault();
    if (navigator.clipboard) {
      navigator.clipboard.writeText(h.host).then(
        function () {
          console.log("Текст успешно скопирован в буфер обмена");
        },
        function (err) {
          console.error("Произошла ошибка при копировании текста: ", err);
        }
      );
    }

    setAnchorEl(event.currentTarget);
    setOpenCopy(true);

    setTimeout(() => {
      setOpenCopy(false);
    }, 1000);
  };

  const handleTooltipClose = () => {
    setOpen(false);
  };
  const handlePingClose = () => {
    setOpenPing(false);
  };
  const handlePingOpen = () => {
    setOpenPing(true);
  };

  const handleTooltipOpen = () => {
    if (
      h.alive &&
      (lastSegment === "101" || lastSegment === "102" || lastSegment === "103")
    ) {
      setLoadFiscal(true);
      setOpen(true);
      return fetchCashFiscalData(h.host);
    }
  };
  return (
    <Card
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        alignContent: "center",
      }}
    >
      <CardHeader
        avatar={renderHostName(h.host)[1]}
        title={renderHostName(h.host)[0]}
        titleTypographyProps={{ variant: "h6" }}
        subheader={
          <>
            <StyledDiv onClick={handleClick}>{h.host}</StyledDiv>
            <Popper open={openCopy} anchorEl={anchorEl} placement="bottom">
              <Alert severity="info" icon={<FileCopy />}>
                IP адрес скопирован!
              </Alert>
            </Popper>
          </>
        }
      />
      <CardActions>
        <ClickAwayListener onClickAway={handlePingClose}>
          <div>
            <Tooltip
              slotProps={{
                popper: {
                  modifiers: [
                    {
                      name: "offset",
                      options: {
                        offset: [0, -12],
                      },
                    },
                  ],
                },
              }}
              placement="top"
              arrow
              PopperProps={{
                disablePortal: true,
              }}
              onClose={handleTooltipClose}
              open={openPing}
              disableFocusListener
              disableHoverListener
              disableTouchListener
              title={`Max: ${h.max || "-"} | Min: ${h.min || "-"}`}
            >
              <IconButton onClick={handlePingOpen} size="small">
                <EthernetIcon color={h.alive ? "success" : "error"} />
              </IconButton>
            </Tooltip>
          </div>
        </ClickAwayListener>
        {isCash && (
          <ClickAwayListener onClickAway={handleTooltipClose}>
            <div>
              <Tooltip
                slotProps={{
                  popper: {
                    modifiers: [
                      {
                        name: "offset",
                        options: {
                          offset: [0, -12],
                        },
                      },
                    ],
                  },
                }}
                placement="top"
                arrow
                PopperProps={{
                  disablePortal: true,
                }}
                onClose={handleTooltipClose}
                open={open}
                disableFocusListener
                disableHoverListener
                disableTouchListener
                title={
                  loadFiscal ? (
                    <CircularProgress size={15} />
                  ) : (
                    `TerminalID: ${fiscalData?.terminalId}
                | Статус: ${
                  fiscalData?.currentCount ? "Активный" : "Неактивный"
                } | Смена: ${
                      fiscalData?.zReportData?.CloseTime ? "Закрыта" : "Открыта"
                    } | Очередь чеков: ${
                      fiscalData?.sendReceiptData?.result?.QueuedToSendCount ||
                      "Нет"
                    }`
                  )
                }
              >
                <IconButton onClick={handleTooltipOpen} size="small">
                  <MonetizationIcon color="info" />
                </IconButton>
              </Tooltip>
            </div>
          </ClickAwayListener>
        )}
      </CardActions>
    </Card>
  );
};

export default EquipmentCard;
