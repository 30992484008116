import { action, autorun, makeObservable, observable } from "mobx";

class ObservableAuthStore {
  auth = { success: false };

  constructor() {
    makeObservable(this, {
      auth: observable,
      setAuth: action,
    });

    // autrun будет срабатывать каждый раз при изменении состояния
    autorun(() => {
      console.log(this.report);
    });
  }

  setAuth(auth) {
    this.auth = auth;
  }
}

const authStore = new ObservableAuthStore();
export default authStore;
