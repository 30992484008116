import React, { useEffect, useState, useCallback } from "react";
import {
  Button,
  TextField,
  Typography,
  Grid,
  CircularProgress,
  Box,
} from "@mui/material";
import { Search as SearchIcon } from "@mui/icons-material";
import axios from "axios";
import EquipmentCard from "./EquipmentCard";

const Shop = () => {
  const [shopNumber, setShopNumber] = useState("");
  const [status, setStatus] = useState(null);
  const [lastUpdate, setLastUpdate] = useState(null);
  const [nextUpdate, setNextUpdate] = useState(null);
  const [equipmentStatuses, setEquipmentStatuses] = useState([]);
  const [loading, setLoading] = useState(false);
  const [currentTime, setCurrentTime] = useState(
    new Date().toLocaleTimeString()
  );

  const testEquip = [
    { alive: false, error: "TEST", host: "172.31.31.60" },
    { alive: true, max: "37.2", min: "35", host: "172.31.31.101" },
    { alive: true, max: "7.2", min: "5.3", host: "172.31.31.102" },
    { alive: true, max: "9.2", min: "2.3", host: "172.31.31.10" },
    { alive: true, max: "5", min: "3", host: "172.31.31.254" },
    { alive: true, max: "10", min: "1", host: "172.31.31.160" },
  ];

  useEffect(() => {
    const timer = setInterval(() => {
      setCurrentTime(new Date().toLocaleTimeString());
    }, 1000);
    return () => clearInterval(timer);
  }, []);

  const fetchPingResults = useCallback(async () => {
    if (shopNumber) {
      setEquipmentStatuses([]);
      setLoading(true);
      try {
        const response = await axios.get(
          `https://webapp.bsolution.uz/backend/ping/${shopNumber}`,
          {
            referrerPolicy: "unsafe-url",
          }
        );
        const data = response.data;
        setEquipmentStatuses(data);
        const date = new Date();
        const nextDate = new Date();
        nextDate.setSeconds(date.getSeconds() + 30);
        setLastUpdate(date.toLocaleTimeString());
        setNextUpdate(nextDate.toLocaleTimeString());
      } catch (error) {
        console.error("Error fetching ping results:", error);
      } finally {
        setLoading(false);
      }
    }
  }, [shopNumber]);

  useEffect(() => {
    if (shopNumber) {
      const intervalId = setInterval(fetchPingResults, 30000);
      return () => clearInterval(intervalId);
    }
  }, [shopNumber, fetchPingResults]);

  const handleSearch = () => {
    setStatus(`Магазин №${shopNumber}`);
    setEquipmentStatuses([]);
    fetchPingResults();
  };

  const handleShopNumberChange = (e) => {
    setShopNumber(e.target.value);
    setStatus(null);
    setEquipmentStatuses([]);
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      handleSearch();
    }
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Typography variant="h6" gutterBottom align="center">
        Работа с магазином
      </Typography>
      <TextField
        label="Номер магазина"
        variant="outlined"
        value={shopNumber}
        onChange={handleShopNumberChange}
        onKeyPress={handleKeyPress}
        margin="normal"
      />
      <Button
        variant="contained"
        color="primary"
        onClick={handleSearch}
        style={{ marginTop: "10px" }}
        startIcon={<SearchIcon />}
      >
        Искать магазин
      </Button>
      {status && (
        <>
          <Typography
            variant="h6"
            gutterBottom
            style={{ marginTop: "20px" }}
            align="center"
          >
            {status}
          </Typography>
          <Box sx={{ display: "flex", flexDirection: "column" }}>
            <Typography variant="subtitle2" align="center">
              Текущее время:
              {currentTime}
            </Typography>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-around",
                alignItems: "center",
                alignContent: "center",
                textAlign: "center",
              }}
            >
              {loading ? (
                ""
              ) : (
                <Typography variant="subtitle2" align="center">
                  Последнее/следующее обновление: {lastUpdate} / {nextUpdate}
                </Typography>
              )}
            </Box>
          </Box>
          {equipmentStatuses.length > 0 ? (
            <Grid
              container
              sx={{ paddingX: 2, marginTop: 1, justifyContent: "center" }}
              spacing={4}
            >
              {equipmentStatuses.map((h) => (
                <Grid item xs={12} sm={6} md={4} lg={3} key={h.host}>
                  <EquipmentCard h={h} />
                </Grid>
              ))}
            </Grid>
          ) : (
            <Grid
              container
              sx={{
                padding: 2,
                justifyContent: "center",
                alignItems: "center",
              }}
              spacing={4}
            >
              <Grid
                item
                xs={12}
                lg={12}
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <CircularProgress />
              </Grid>
              {/* {testEquip.map((h) => (
                <Grid item xs={12} sm={6} md={4} lg={3} key={h.host}>
                  <EquipmentCard h={h} />
                </Grid>
              ))} */}
            </Grid>
          )}
        </>
      )}
    </Box>
  );
};

export default Shop;
