export const createAppStore = (props) => {
  return {
    test: props.test || "Hello world",
    toggleTest: function () {
      this.test = this.test === "Hi!" ? "How are you?" : "Hi!";
    },
    auth: { success: false },
    setAuth: function () {
      console.log(props);
      this.auth = props.auth;
    },
  };
};
