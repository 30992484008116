import React, { useEffect } from "react";
import MainMenu from "./components/MainMenu";
import Shop from "./components/Shop";
import Header from "./components/Header";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import AuthPage from "./components/TgAuth";
import axios from "axios";
import { observer } from "mobx-react-lite";
import authStore from "./stores/AuthStore/authStore";

const App = observer(() => {
  const store = authStore;
  const tg = window?.Telegram?.WebApp;
  if (tg) {
    tg.expand();
  }

  useEffect(() => {
    if (tg.initDataUnsafe.user) {
      axios
        .post(
          `https://webapp.bsolution.uz/backend/auth`,
          { withCredentials: true },
          { params: tg.initDataUnsafe.user }
        )
        .then((res) => {
          if (res.data.success) {
            store.setAuth({ ...res.data, webapp: true });
          }
        });
    } else {
      axios
        .get("https://webapp.bsolution.uz/backend/auth/check", {
          withCredentials: true,
        })
        .then((res) => {
          if (res.data.success) {
            store.setAuth({ ...res.data });
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
    return () => {};
  }, [tg.initDataUnsafe.user, store]);
  return (
    <>
      <BrowserRouter>
        <Header store={store} />
        {store?.auth?.webapp || store?.auth?.success ? (
          <Routes>
            <Route path="/" element={<MainMenu />} />
            <Route path="/shop" element={<Shop />} />
          </Routes>
        ) : (
          <AuthPage />
        )}
      </BrowserRouter>
    </>
  );
});

export default App;
